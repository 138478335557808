import React from 'react';
import { BiCheck } from 'react-icons/bi';
import styled, { css } from 'styled-components';
import defaultTheme from '../../theme/defaultTheme';

const Wrapper = styled.div`
    background: #eee;
    padding: .8rem 0;
    margin-bottom: 2rem;
  
    @media (${defaultTheme.mobile}) {
        padding: 1.6rem 0;
        background: none;
    }
`;
const MiddleContent = styled.div`
    @media (${defaultTheme.mobile}) {
        padding: 0 2rem;
    }
`;

const Group = styled.ul`
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0;
    margin: 0;


    @media (${defaultTheme.mobile}) {
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -1px;
            border-bottom: 1px dotted #ccc;
        }
    }
`;
const Item = styled.li`
    display: flex;
    width: 33.33%;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    @media (${defaultTheme.mobile}) {
        width: auto;
    }
`; 

interface RoundProps {
    passed?: boolean,
}

const Round = styled.div<RoundProps>`
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: .5rem;
    background: ${defaultTheme.secondary};
    color: white;
    position: relative;
    z-index: 2;
    

    @media (${defaultTheme.mobile}) {
        margin: 0;
        width: 22px;
        height: 22px;

        > * {
            display: none;
        }
    }
    
    ${props => props.passed && css`
        background: white;
        border: 2px solid ${defaultTheme.secondary};
        color: ${defaultTheme.secondary};
    `}
`;


const Label = styled.span`
    display: inline-block;
    font-size: .75em;
    font-weight: 300;

    @media (${defaultTheme.mobile}) {
        display: none;
    }
`;

const CurrentNum = styled.span``;

interface Props {
    currentIndex: number;
}

export default function Breadcrumb(props: Props) {
    const options = [
        {
            name: 'Pedido Iniciado',
        },
        {
            name: 'Detalhes Pessoais'
        },
        {
            name: 'Pagamento'
        }
    ];
    return <Wrapper>
        <MiddleContent className='container'>
            <Group>
                {options.map((row, index) => {
                    return <Item key={row.name}>
                        <Round passed={index == props.currentIndex}>
                            {!(index == props.currentIndex) ?
                                <CurrentNum>{index + 1}</CurrentNum> :
                                <BiCheck
                                color={defaultTheme.secondary} size={28} />
                                }
                        </Round>
                        <Label>{row.name}</Label>
                    </Item>;
                })}
            </Group>
        </MiddleContent>
    </Wrapper>
}