import axios from 'axios';
import config from './config';

const api = axios.create({
  baseURL: config.gatewayUrl
});

api.interceptors.response.use((res) => {
  return res;
}, err => {
  return Promise.reject(err);
});

export default api;
