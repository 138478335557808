import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { SupportedLngs } from '../translations/i18n';
import { Locale } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';

export interface LangContextInterface {
  lang: SupportedLngs;
  dateLocale: Locale;
  setLang: (lng: SupportedLngs) => void;
  formatDate: (date: Date | string) => string;
  label: string | undefined;
  setLabel: Dispatch<SetStateAction<string | undefined>>;
}

export const LangContext = createContext<LangContextInterface>({} as LangContextInterface);

export const LangProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  const initial = (i18n.language as SupportedLngs) || 'en';

  const [lang, setStateLang] = useState<SupportedLngs>(initial);
  const [label, setLabel] = useState<string>();
  const [dateLocale, setDateLocale] = useState<Locale>(processCurrentDateLocale(initial));

  useEffect(() => {
    if (lang === 'en') {
      setLabel('English');
    }
    if (lang === 'pt') {
      setLabel('Português');
    }
  }, [lang]);

  function processCurrentDateLocale(dlang: SupportedLngs) {
    if (dlang === 'pt') {
      return ptBR;
    } else {
      return enUS;
    }
  }

  function setLang(lang: SupportedLngs) {
    setStateLang(lang);
    i18n.changeLanguage('EN');
    const locale = processCurrentDateLocale(lang);
    setDateLocale(locale);
    document.body.click();
  }

  const formatDate = (date: Date | string) => {
    const typedDate = typeof date == 'string' ? new Date(date) : date;

    return typedDate ? format(typedDate, 'dd MMM yy - HH:mm', { locale: dateLocale }) : '';
  };

  return (
    <LangContext.Provider value={{ formatDate, dateLocale, lang, setLang, label, setLabel }}>
      {children}
    </LangContext.Provider>
  );
};

export function useLang(): LangContextInterface {
  const context = useContext(LangContext);
  return context;
}
