export const presets = {
  local: {
    gatewayUrl: 'https://dev.api.safepag.com/gateway',
    environment: 'local',
    checkoutUiUrl: window.location.origin,
    assetsUrl: '/',
  },
  dev: {
    gatewayUrl: 'https://dev.api.safepag.com/gateway',
    environment: 'dev',
    checkoutUiUrl: 'https://dev.checkout.safepag.com/',
    assetsUrl: '',
  },
  tst: {
    gatewayUrl: 'https://tst.api.safepag.com/gateway',
    environment: 'tst',
    checkoutUiUrl: 'https://tst.checkout.safepag.com/',
    assetsUrl: '',
  },
  stg: {
    gatewayUrl: 'https://staging.api.safepag.com/gateway',
    environment: 'stg',
    checkoutUiUrl: 'https://staging.checkout.safepag.com/',
    assetsUrl: '',
  },
  prod: {
    gatewayUrl: 'https://api.safepag.com/gateway',
    environment: 'prod',
    checkoutUiUrl: 'https://checkout.safepag.com/',
    assetsUrl: '',
  }
};


let config = presets.dev;

if (window.location.host.indexOf('dev.checkout') === 0) {
  config = presets.dev;
}

if (window.location.host.indexOf('stg.checkout') === 0) {
  config = presets.stg;
}

if (window.location.host.indexOf('staging.checkout') === 0) {
  config = presets.stg;
}

if (window.location.host.indexOf('checkout') === 0) {
  config = presets.prod;
}

if (window.location.host.indexOf('tst.checkout') === 0) {
  config = presets.tst;
}

if (window.location.host.indexOf('localhost') === 0) {
  config = presets.local;
}

export default config;
