import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../theme/defaultTheme';
import SafepagLogo from './SafepagLogo';

const FooterWrapper = styled.div`
    padding: 2rem 2rem;
    display: flex;
    flex-wrap: wrap;

    @media (${defaultTheme.mobile}) {
        padding: 0 2rem;
    }
`;

const FlowRight = styled.div`
    margin-left: auto;
`;

const Footer: React.FC = () => {
    return <FooterWrapper className='container'>
        <FlowRight>
            <SafepagLogo />
        </FlowRight>
    </FooterWrapper>
}

export default Footer;