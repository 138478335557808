import React from 'react';
import styled from 'styled-components';
import SecurityFlag from '../common/SecurityFlag';
import { IoReturnUpBack } from 'react-icons/io5';
import { useLayoutInfo } from '../../contexts/GlobalContext';
import defaultTheme from '../../theme/defaultTheme';

const HeaderWrapper = styled.div`
    width: 100%;
    z-index: 12;
    padding: 1rem 2.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);

    @media (${defaultTheme.mobile}) {
        padding: 1rem;
    }
`;

const Image = styled.img`
    max-width: 123px;
    max-height: 60px;
    display: inline-block;
`;

const LogoWrapper = styled.div`
    width: 123px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${defaultTheme.mobile}) {
        margin: 0;
        padding: 0;
    }

`;

const Column = styled.div`

`;

const Md = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BackWrapper = styled.div`
    margin-right: 1rem;
`;

const BackButton = styled.a`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;

    @media (${defaultTheme.mobile}) {
        display: none;
    }
`;

const Line = styled.span`
    height: 24px;
    width: 1px;
    display: block;
    background: #999;
    margin: 0 1.4rem;

    @media (${defaultTheme.mobile}) {
        margin: 0 1rem;
        background: #ccc;
    }
`;

const PageTitle = styled.h2`
    margin: 0;
    font-size: 1em;
    color: #333;

    @media (${defaultTheme.mobile}) {
        font-size: .9em;
    }
`;

const BrandText = styled.h2`
    font-size: .9em;
    margin: 0;
`;

const Header: React.FC = () => {

    const { info } = useLayoutInfo();

    return <HeaderWrapper className='container-fluid'>
        <Column>
            <Md>
                <BackWrapper>
                    <BackButton href='/'>
                        <IoReturnUpBack size={30} color='#999' />
                    </BackButton>
                </BackWrapper>
                <LogoWrapper>
                    {info?.logo ? <Image src={info?.logo} alt={info?.name} /> : 
                        <BrandText>{info?.name}</BrandText>
                    }
                    
                </LogoWrapper>
                <Line />
                <PageTitle>Pagamento</PageTitle>
            </Md>
        </Column>
        <Column>
            <SecurityFlag />
        </Column>
    </HeaderWrapper>
}

export default Header;