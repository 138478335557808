import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../theme/defaultTheme';
import CopiaEColaText from '../../common/CopiaEColaText';
import Countdown from '../../common/Countdown';
import PartnerAlert from '../../common/PartnerAlert';
import Resume from '../../common/Resume';
import Breadcrumb from "../../common/Breadcrumb";

export interface Props {
    data: {
        amount: string;
        currency: string;
        id: string;

        externalReference: string;

        accountName: string;
        accountBrandImage: string;
        qrCodeText: string;
        qrCodeImageBase64: string;
        qrCodeIsExpired: boolean;
        qrCodeExpiresIn: number;
        qrCodeCreatedAt: string;
        paymentDescriptionInfo?: string;

        pspDocumentNumber: string;
        pspLegalName: string;
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InfoWrapper = styled.div`
    color: #9a9a9a;
    width: 60%;
    padding: 0 2rem;
    padding-left: 0;

    @media (${defaultTheme.mobile}) {
        width: 100%;
        padding: 0 2rem;
    }
`;

const ResumeWrapper = styled.div`
    width: 40%;
    padding: 0 1.2rem;
    padding-right: 0;
    @media (${defaultTheme.mobile}) {
        width: 100%;
        padding: 0 2rem;
    }
`;


const QRBox = styled.div`
    display: flex;
    justify-content: space-between;
`;

const QrContent = styled.div`
    text-align: left;
    width: 45%;
`;

const QrDetails = styled.div`
    text-align: right;
    width: 55%;
`;

const Item = styled.div`
    font-size: .92em;
    margin-bottom: 2rem;
`;

const ItemVal = styled.div`
    font-size: 1.1em;
    font-weight: bold;
    margin: .50rem 0;
    color: #13263B;
`;

const ExternalRefWrapper = styled.span`
    max-width: 130px;
    overflow: hidden;
    word-break: break-word;
    display: inline-block;
    
`;

const QrImage = styled.img`
    max-height: 240px;
    max-width: 100%;
`;

const QrImageWrapper = styled.div``; 


export default function QrCodeVersionA({ data }: Props) {
    const {
        amount,
        qrCodeImageBase64,
        accountName,
        qrCodeText,
        externalReference,
        paymentDescriptionInfo,
        qrCodeExpiresIn,
        qrCodeCreatedAt,
        pspLegalName,
        pspDocumentNumber
    } = data;

    return (
        <>
            <Breadcrumb currentIndex={2} />
            <div className="container">
                <Wrapper>
                    <InfoWrapper>
                        <QRBox>
                            <QrContent>
                                <Item>
                                    QR Code do PIX:
                                    <QrImageWrapper>
                                        <QrImage src={qrCodeImageBase64} alt='QR Code' />
                                    </QrImageWrapper>
                                </Item>
                            </QrContent>
                            <QrDetails>
                                <Item>
                                    Código do pedido:
                                    <ItemVal>
                                        <ExternalRefWrapper title={externalReference}>
                                            {externalReference}
                                        </ExternalRefWrapper>
                                    </ItemVal>
                                </Item>
                                <Item>
                                    QR Code expira em:
                                    <ItemVal>
                                        <Countdown
                                            onExpired={() => {
                                            }}
                                            fallbackOnExpired='Expired'
                                            baseDate={qrCodeCreatedAt}
                                            expiresIn={qrCodeExpiresIn}
                                        />
                                    </ItemVal>
                                </Item>
                            </QrDetails>
                        </QRBox>

                        <Item>
                            Pix copia e cola:
                            <ItemVal>
                                <CopiaEColaText text={qrCodeText} />
                            </ItemVal>
                        </Item>

                        <Item>Destinatário: <ItemVal>{pspLegalName}</ItemVal></Item>

                        <Item>CNPJ:
                            <ItemVal>
                                {pspDocumentNumber}
                            </ItemVal>
                        </Item>
                    </InfoWrapper>

                    <ResumeWrapper>
                        <Resume
                            total={amount}
                            paymentDescriptionInfo={paymentDescriptionInfo}
                            merchantName={accountName}
                        />
                        <PartnerAlert />
                    </ResumeWrapper>
                </Wrapper>
            </div>
        </>
    )
}

