import React from 'react';
import styled from 'styled-components';
import loadAsset from '../../utils/loadAsset';

const Img = styled.img`
    display: inline-block;
    max-width: 100px;
    vertical-align: middle;
`;

const Text = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
    font-size: .9em;
    margin-right: .4rem;
`;

const Wrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

export default function SafepagLogo() {
    return <Wrapper>
        <Text>
            Powered by
        </Text>
        <Img src={loadAsset('images/safepag-logo.png')} alt='Safepag Brand Logo' />
    </Wrapper>
}