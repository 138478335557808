import React from 'react';
import styled from 'styled-components';
import loadAsset from '../../utils/loadAsset';

interface Props {
    children: string;
    type?: 'info' | 'warning';
    icon?: React.ReactNode;
}

const Wrapper = styled.div`
    background: transparent;
    padding: .80rem 2rem;
    border-radius: 0;
    margin: 0 0 2rem 0;
    align-items: center;
    display: flex;
    border: 1px solid #DDD;
    font-size: .8em;
`;

const ImgWrap = styled.span`
    display: inline-block;
    margin-right: 1rem;
    
`;

const Icon = styled.img`
    max-width: 50px;
`;

const Alert: React.FC<Props> = ({ children, icon }) => {
    return <Wrapper>
        <ImgWrap>
            {icon ? icon : <Icon src={loadAsset('images/icon-info.svg')} alt='Info icon' />}
        </ImgWrap>
        <p>
            {children}
        </p>
    </Wrapper>;
}

export default Alert;