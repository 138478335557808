import React from 'react';
import styled from 'styled-components';
import BeatLoader from "react-spinners/BeatLoader";

const Wrapper = styled.div`
    width: 100%;
    padding: 2rem 0;
    text-align: center;
    
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50vh;
`;

export default function LoadingFull() {
    return <Wrapper>
        <BeatLoader size={20} color='#9a9a9a' />
    </Wrapper>;
}