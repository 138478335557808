import React, { useState } from 'react';
import urlJoin from 'url-join';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Dialog, FormField, TextareaField, TextInputField, Button, Combobox } from 'evergreen-ui';
import envs from '../utils/envs';
import config, { presets } from '../services/config';
import SetupModalV1 from './v1/SetupModalV1';

const Middle = styled.div`
    margin: 0 auto;
    padding: 2rem;
    max-width: 650px;
`;

interface V2Fields {
    guid: string;
    env?: keyof (typeof presets);
    hash: string;
    json?: boolean;
    jsonText?: string;
}

const Home: React.FC = () => {
    const [modal, setModal] = useState<boolean>(false);
    const [modalV1, setModalV1] = useState<boolean>(false);

    const {
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        register
    } = useForm<V2Fields>({
    });
    const values = watch();

    function openForm(data: V2Fields) {
        const currentConfig = presets[data.env || 'local'];
        console.info('current config', currentConfig, data.env);
        window.open(urlJoin(currentConfig.checkoutUiUrl, '/v2/', data.guid, data.hash), '_blank');
    }

    return <Middle>
        <p>
            <Button size='large' onClick={() => {
                setModalV1(true);
            }}>
                V1 Redirect Checkout (JWT)
            </Button>
        </p>
        <p>
            <Button size='large' onClick={() => {
                setModal(true);
            }}>
                V2 Redirect Checkout
            </Button>
        </p>

        <SetupModalV1 show={modalV1} onClose={() => setModalV1(false)} />

        <Dialog
            hasFooter={false}
            isShown={modal}
            onCancel={() => setModal(false)}
            title='Redirect Checkout'>

            <form autoComplete='off' onSubmit={handleSubmit(data => openForm(data))}>

                {values.json ? <TextareaField
                    label='JSON'
                    placeholder='{}'
                    {...register('jsonText')}
                    validationMessage={errors.json?.message}
                /> : null}

                {!values.json ? <>
                    <TextInputField
                        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                            const possiblyJson = e.clipboardData.getData('Text');
                            console.info('possibily json', e);
                            try {
                                const data = JSON.parse(possiblyJson);
                                if (data.guid && data.hash) {
                                    setTimeout(function () {
                                        setValue('guid', data.guid.trim());
                                        setValue('hash', data.hash.trim());
                                    }, 1);
                                } else {
                                    setValue('guid', '');
                                }
                            } catch (err) {

                            }
                        }}
                        label='Payment indentifier'
                        placeholder='guid'
                        {...register('guid', { required: true })}
                        validationMessage={errors.guid?.message}
                    />
                    <TextInputField
                        label='Hash'
                        type='text'
                        placeholder='hash'
                        validationMessage={errors.hash?.message}
                        {...register('hash', { required: true })}
                    />
                </> : null}
                <FormField marginBottom={32} label='Environment'>
                    <Combobox
                        openOnFocus
                        initialSelectedItem={envs.find(e => e === config.environment)}
                        items={
                            envs
                        }
                        onChange={e => setValue('env', e)}
                    />
                </FormField>

                <Button type='submit' marginBottom={32} appearance='primary'>Go To Checkout</Button>
            </form>
        </Dialog>
    </Middle>
}

export default Home;