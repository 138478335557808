import React from 'react';
import styled from 'styled-components';
import loadAsset from '../../utils/loadAsset';
import Alert from './Alert';

const Icon = styled.img`
    max-width: 60px;
    height: auto;
    display: inline-block;
`;

export default function PartnerAlert() {
    return <Alert icon={<Icon src={loadAsset('images/icon-money.svg')} alt='Money icon' />}>
        O pagamento será finalizado através de uma empresa parceira e, uma vez identificado, será atualizado no site de origem.
    </Alert>
}