import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingFull from '../../components/common/LoadingFull';
import PaymentFormVersionA, { FormData } from '../../components/layout/a/PaymentFormVersionA';
import api from '../../services/api';
import { PayInJwtDecodedBody, PayInResultData } from '../../types/payin';
import { decodeJwt } from '../../utils/jwt';
import Breadcrumb from "../../components/common/Breadcrumb";
import {useLayoutInfo} from "../../contexts/GlobalContext";

export default function PaymentFormV1() {
  const navigate = useNavigate();
  const { jwt, apiKey } = useParams<{ apiKey: string; jwt: string }>();
  const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { info: merchantInfo, setMerchantInfo } = useLayoutInfo();
  const jwtDecoded: PayInJwtDecodedBody | undefined = jwt ? decodeJwt<PayInJwtDecodedBody>(jwt) : undefined;

  function goToQrCode(data: PayInResultData) {
    navigate(`/direct/deposit/${apiKey}/${jwt}/qr`, {
      state: data,
      replace: true
    });
  }

  useEffect(() => {
      console.log("api-key", apiKey);
      api.get('/v1/payin/logo', {headers: { 'x-api-key': apiKey || ''}})
          .then((res) => {
              setMerchantInfo({logo: res.data.accountBrandImage, name: jwtDecoded?.entityName || ''});
              console.log("merchantInfo", merchantInfo);
          })
  }, [])

  useEffect(() => {
    console.info('PaymentFormV1', 'jwt_json', jwtDecoded, jwt);
    setLoading(true);

    api
      .post<PayInResultData>(
        '/v1/payin',
        {
          jwt
        },
        {
          headers: {
            'x-api-key': apiKey || ''
          }
        }
      )
      .then((res) => {
          setMerchantInfo({logo: res.data.accountBrandImage, name: res.data.accountName});
        setLoading(false);
        goToQrCode(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  function onSubmitForm(data: FormData) {
    setFormSubmitting(true);
    api
      .post<PayInResultData>(
        '/v1/payin',
        {
          jwt,
          customerDocument: data.customerDocument,
          customerName: data.customerName,
          customerEmail: data.customerEmail
        },
        {
          headers: {
            'x-api-key': apiKey || ''
          }
        }
      )
      .then((res) => {
        setFormSubmitting(false);
        goToQrCode(res.data);
      })
      .catch((err) => {
        console.error(err);
        if(err.response.data.error != undefined) {
            navigate('/error')
        }
        setFormSubmitting(false);
      });
  }

  return (
    <>
      <Breadcrumb currentIndex={1} />
      <div className="container">
      {loading ? <LoadingFull /> : undefined}

      {!loading ? (
        <PaymentFormVersionA
          onSubmitForm={(data: FormData) => {
            onSubmitForm(data);
          }}
          isSubmitting={isFormSubmitting}
          formData={{
            customerName: jwtDecoded?.customerProvidedName,
            customerDocument: jwtDecoded?.customerDocument,
            customerEmail: jwtDecoded?.customerEmail
          }}
          paymentParams={{
            paymentDescription: jwtDecoded?.paymentDescriptionInfo || '',
            merchantName: jwtDecoded?.entityName || '',
            tax: undefined,
            shipping: undefined,
            total: `${jwtDecoded?.amount}`
          }}
        />
      ) : undefined}
      </div>
    </>
  );
}
