import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

export type SupportedLngs = 'en' | 'pt';

export interface DetailedLangs {
  value: SupportedLngs;
  label: string;
}

export const langs = [
  { value: 'en', label: 'English' },
  { value: 'pt', label: 'Português' }
] as DetailedLangs[];

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['pt', 'en'],
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
