import React from 'react';
import styled from 'styled-components';
import { BsShieldCheck } from 'react-icons/bs';
import defaultTheme from '../../theme/defaultTheme';

const ImgBox = styled.div`
    margin-right: 1.0rem;
    display: inline-block;
`;

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    vertical-align: middle;
    color: #999;
    font-size: .8em;

    strong {
        display: block;
        font-size: 1.2em;
    }

    span {
        @media (${defaultTheme.mobile}) {
            display: none;
        }
    }
`;

export default function SecurityFlag() {
    return <Wrapper>
        <ImgBox>
            <BsShieldCheck color='#999' size={30} />
        </ImgBox>
        <span>
            Você está em uma <strong>página segura</strong>
        </span>
    </Wrapper>
}