import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BiCheck, BiCopy } from 'react-icons/bi';
import copyToClipboard from '../../utils/copyToClipboard';

interface CopiaEColaTextProps {
    text?: string;
}

const Text = styled.span`
    font-weight: normal;
    font-size: .8em;
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
`;

const Wrapper = styled.div`
    padding-right: 90px;  
    position: relative;
    display: flex;
`;


const IconWra = styled.button`
    z-index: 2;
    background: none;
    border: 1px solid #DDD;
    padding: .5rem .5rem;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: center;

    svg {
        position: relative;
        top: -2px;
        margin-right: .4rem;
    }

    &:hover {
        background: #eee;
    }
`;

const CopiaEColaText: React.FC<CopiaEColaTextProps> = ({ text }) => {

    const [ clicked, setClicked ] = useState<boolean>(false);

    let timeout: NodeJS.Timeout | undefined;

    const reducedText = useMemo(() => {
        return text?.slice(0, 110) + '...';
    }, [ text ]);

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, []);

    return <Wrapper role='button' title='Clique para copiar' onClick={() => {
        if (text) {
            setClicked(true);

            setTimeout(function() {
                setClicked(false);
            }, 3000);
            
            copyToClipboard(text);
        }
    }}>
        <Text>{reducedText}</Text>
        <IconWra>
            {clicked ? <BiCheck size={20} /> : <BiCopy size={20} />}
            {clicked ? <span>Copiado</span> : <span>Copiar</span>}
        </IconWra>
    </Wrapper>;
};

export default CopiaEColaText;