
import { AxiosError } from 'axios';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HiOutlineEmojiSad } from 'react-icons/hi';

const Wrapper = styled.div`
    padding: 0 1rem;
`;

const Side = styled.div`
    display: flex;
    align-items: center;
`;

const Text = styled.article`
    padding-left: 1rem;
    color: #9a9a9a;
`;

const ErrorCodeInfo = styled.div`
    text-align: left;
    margin: 0 0 .65rem 0;
`;

const ErrorTile = styled.div`
    font-size: 1.4em;
    text-align: left;
    font-weight: bold;
    color: #333;
    margin: 0 0 .65rem 0;
`;


interface ErrorLocation {
    error?: AxiosError;
    guid?: string;
}

const ErrorV2: React.FC = () => {
    const location = useLocation();
    const { guid } = useParams();

    const state = location.state as ErrorLocation;

    return <>
        <Wrapper>
            <Side>
                <HiOutlineEmojiSad size={130} color='#333' />
                <Text>
                    <ErrorTile>Não foi possível localizar este pagamento</ErrorTile>
                    <ErrorCodeInfo>{state.error?.message}.</ErrorCodeInfo>
                    {guid ? <ErrorCodeInfo>Código: {guid}.</ErrorCodeInfo> : undefined}
                </Text>
            </Side>
        </Wrapper>
    </>;
};

export default ErrorV2;