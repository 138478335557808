import React from 'react';
import styled from 'styled-components';
import formatCurrency from '../../utils/formatCurrency';


const Wrapper = styled.div`
    margin-bottom: 2rem;
`;

const Box = styled.div`
    border: 1px solid #DEDDDD;
    font-size: .9em;
    color: #3D404C;
    padding: 2rem;

    dl {
        margin: 0;
        padding-bottom: 2rem;
    }

    th,
    dt {
        font-weight: 300;
        font-size: .9em;
        margin: 0 0 .3rem 0;
        text-align: left;
    }

    td,
    dd {
        margin: 0 0 1.2rem 0;
        padding: 0;
        font-weight: bold;
        font-size: 1.2em;
        &:last-child {
            margin: 0;
        }
    }

    table {
        width: 100%;
        margin-bottom: 1rem;

        th,
        td {
            border: 0;
            padding: .5rem 0;
            margin: 0;
        }

        td {
            margin: 0;
            text-align: right;
        }
    }
`;


const Total = styled.div`
    color: #049056;
    font-size: 1.8em;
    letter-spacing: -0.015em;
    text-align: right;
    font-weight: 800;
    padding: 1rem 0 0;
    border-top: 1px solid #DEDDDD;
`;

const Line = styled.div`
    border-top: 1px solid #DEDDDD;
`;


interface Props {
    merchantName: string;
    paymentDescriptionInfo?: string;
    tax?: string;
    total: string;
    shipping?: string;
}

const Resume: React.FC<Props> = ({
    paymentDescriptionInfo,
    merchantName,
    total,
    tax,
    shipping
}: Props) => {
    return <Wrapper>
        <Box>
            <dl>
                <dt>Comerciante</dt>
                <dd>
                    {merchantName}
                </dd>
                {paymentDescriptionInfo != '' ? <>
                    <dt>Pagamento de</dt>
                    <dd>
                        {paymentDescriptionInfo}
                    </dd>
                </> : undefined}                
            </dl>

            {Boolean(tax && shipping) ? (
                <>
                    <Line />
                    <table>
                        <tbody>
                            <tr>
                                <th>Shipping</th>
                                <td>{formatCurrency(shipping)}</td>
                            </tr>
                            <tr>
                                <th>Tax</th>
                                <td>{formatCurrency(tax)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>

            ) : undefined}

            <Total title='Total: '>{formatCurrency(total)}</Total>
        </Box>
    </Wrapper>
}
export default Resume;