import styled from 'styled-components';

const ButtonStyled = styled.button`
    padding: 1.4rem 1rem;
    font-size: 1.1em;
    width: 100%;
    border-radius: 5px;
    border: 0;
    background: #049056;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .2s all ease;

    &:hover {
        background: #07b36c;
        color: white;
        box-shadow: 0 3px 8px rgba(0,0,0,.1);
        transition: .2s all ease;
    }
`;

export default ButtonStyled;
