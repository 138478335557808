import sign from 'jwt-encode';
import decoder from 'jwt-decode';

export function encodeJwt<T>(data: T, secret: string) {
  return sign(data, secret);
}

export function decodeJwt<T>(jwt: string) {
  return decoder(jwt) as T;
}
