import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../header/Header';
import Footer from '../common/Footer';
import defaultTheme from '../../theme/defaultTheme';

const Middle = styled.div`
    padding: 0;
    margin-bottom: 4rem;
    @media (${defaultTheme.mobile}) {
        padding: 2rem 0;
    }
`;

const RedirectCheckoutWrapper: React.FC = () => {
    return <>
        <Header />
        <Middle>
                <Outlet />
        </Middle>
        <Footer />
    </>;
};

export default RedirectCheckoutWrapper;