import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../theme/defaultTheme';
import loadAsset from '../../../utils/loadAsset';

interface Props {
    amount?: string;
    currency?: string;
    id?: string;

    externalReference?: string;

    accountName?: string;
    accountBrandImage?: string;
    qrCodeText?: string;
    qrCodeImageBase64?: string;
    qrCodeIsExpired: boolean;
    qrCodeExpiresIn: number;
    qrCodeCreatedAt?: string;
    paymentDescriptionInfo?: string;

    pspDocumentNumber?: string;
    pspLegalName?: string;
}

const Wrapper = styled.div`

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: ${defaultTheme.primary};

    @media (${defaultTheme.mobile}) {
        padding: 0 2rem;
    }
`;

const Title = styled.h1`
    font-size: 2em;
    margin-bottom: 3rem;
`;

const Text = styled.p`
    font-size: 1em;
    margin: 0;
  
    margin-bottom: 1.2rem;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

const Box = styled.div`
    width: 50%;
    padding: 1.2rem 0;
    align-items: center;

    @media (${defaultTheme.mobile}) {
        width: auto;
    }
`;

const IconLeft = styled.div`
    width: 50%;
    padding: 0 2rem;

    @media (${defaultTheme.mobile}) {
        width: auto;
        margin-bottom: 2rem;
        padding: 0;
    }
`;

const Image = styled.img`
    height: auto;
    display: block;
    max-width: 100%;

    @media (${defaultTheme.mobile}) {
        width: 200px;
    }
`;

export default function QRCodeExpiredVersionA({}: Props) {
    return <Wrapper>

        <IconLeft>
            <Image src={loadAsset('images/broken-qrcode.png')} alt='QR Code Expirado' />
        </IconLeft>

        <Box>
        
            <Title>Ooops!</Title>

            <Text>Parece que seu QR Code expirou!</Text>
            <Text>Preencha novamente seus dados para que possamos gerar um novo código para você</Text>

        </Box>

    </Wrapper>
}