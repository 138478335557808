import { createContext, Dispatch, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PayInJwtDecodedBody, PayInResultData } from '../types/payin';
import { decodeJwt } from '../utils/jwt';

export type CheckoutPaymentType = 'PIX' | 'BOLETO' | 'PAYZ';

export interface FormInterface {
  name?: string;
  email?: string;
  cpf?: string;
}

export interface ErrorInterface {
  code: string;
  error: string;
}

export interface DecodedParamsInfo {
  data: PayInJwtDecodedBody | undefined;
  isValid: boolean;
  missingParams: string[];
}

export interface CheckoutContextInterface {
  submitted: boolean;
  setSubmitted: Dispatch<React.SetStateAction<boolean>>;

  isSelectedPayment: CheckoutPaymentType;
  setIsSelectedPayment: Dispatch<React.SetStateAction<CheckoutPaymentType>>;

  payInResult: PayInResultData | undefined;
  setPayInResult: Dispatch<React.SetStateAction<PayInResultData | undefined>>;

  loading: boolean;
  setLoading: Dispatch<React.SetStateAction<boolean>>;

  error?: ErrorInterface;
  setError: Dispatch<React.SetStateAction<ErrorInterface | undefined>>;

  getRawJwt: () => string | undefined;
  getApiKey: () => string | undefined;

  getDecodedParams: () => DecodedParamsInfo;

  reset: () => void;
}

export const CheckoutContext = createContext<CheckoutContextInterface>({} as CheckoutContextInterface);

export const CheckoutProvider: React.FC = ({ children }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isSelectedPayment, setIsSelectedPayment] = useState<CheckoutPaymentType>('PIX');
  const [payInResult, setPayInResult] = useState<PayInResultData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorInterface | undefined>();

  const getRawJwt = () => {
    const { jwtToken } = useParams();
    return jwtToken || undefined;
  };

  const getApiKey = () => {
    const { apiKey } = useParams();
    return apiKey || undefined;
  };

  const getDecodedParams = () => {
    const jwtToken = getRawJwt();
    try {
      const data = jwtToken ? (decodeJwt(jwtToken) as PayInJwtDecodedBody) : undefined;
      return {
        data: data,
        isValid: Boolean(data),
        missingParams: [
          data?.customerProvidedName ? '' : 'customerProvidedName',
          data?.customerDocument ? '' : 'customerDocument',
          data?.customerEmail ? '' : 'customerEmail'
        ].filter((field) => field)
      };
    } catch (err) {}

    return {
      data: undefined,
      isValid: false,
      missingParams: []
    };
  };

  const reset = () => {
    setPayInResult(undefined);
    setLoading(false);
    setSubmitted(false);
    setError(undefined);
  }

  return (
    <CheckoutContext.Provider
      value={{
        submitted,
        setSubmitted,
        isSelectedPayment,
        setIsSelectedPayment,
        payInResult,
        setPayInResult,
        loading,
        setLoading,
        error,
        setError,

        getRawJwt,
        getApiKey,
        getDecodedParams,
        reset,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export function useCheckout(): CheckoutContextInterface {
  const context = useContext(CheckoutContext);
  return context;
}
