import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    position: relative;
    background: #FFF;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 18px;
    cursor: default;
  }

  * {
    box-sizing: border-box;
  }

  .container {
    margin: 0 auto;
    max-width: 920px;
    width: 100%;
  }

  h1, h2, h3, h4 {
    margin: 0 0 1rem 0;
  }

  .container-fluid {
    margin: 0 auto;
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  a {
    color: black;
  }
`;
 
export default GlobalStyle;