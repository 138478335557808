import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingFull from '../../components/common/LoadingFull';
import QrCodeVersionA from '../../components/layout/a/QrCodeVersionA';
import { PayInResultData } from '../../types/payin';


export default function QRCodeV1() {
    const [loading] = useState<boolean>(false);

    const location = useLocation();
    const state: PayInResultData = location.state as PayInResultData;

    useEffect(() => {
        // setLoading(true);
        if (!state?.qrCodeImageBase64) {
            // navigate(`/direct/deposit/${apiKey}/${jwt}`, {
            //     replace: true
            // });
        } else {
            // setLoading(false);
        }
    }, [state]);

    return <>
        {loading ? <LoadingFull /> : undefined}

        {Boolean(!loading && state) ? <>
            <QrCodeVersionA
                data={state}
            />
        </> : undefined}

    </>
}