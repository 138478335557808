import { useCountDown } from 'use-countdown-hook';
import React, { useCallback } from 'react';
import styled from 'styled-components';

interface CountdownProps {
    expiresIn: number;
    baseDate: string;
    fallbackOnExpired: string;
    onExpired: () => void;
}

const Ball = styled.div`
    width: 4px;
    height: 4px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    margin-right: .55rem;
`;


const Wrapper = styled.div`
`;

const Text = styled.span`
    font-weight: bold;
    font-size: 1.1em;
`;


const Countdown: React.FC<CountdownProps> = ({ onExpired, expiresIn, baseDate }) => {

    const onTimeOver = useCallback(() => {
        onExpired();
    }, []);

    const setTimer = (expires: number, createdAt: string) => {
        const expiration = expires * 60000;
        const today = new Date().getTime();

        const toLocale = new Date(`${createdAt} UTC`).toString();
        const toMilliseconds = new Date(toLocale).getTime();

        const time = today - toMilliseconds;
        return expiration - time;
    };

    const [{ hh, mm, ss }] = useCountDown(setTimer(expiresIn, baseDate), {
        startImmediately: true,
        onTimeOver
    });

    return <Wrapper>
        <Ball />
        <Text>
            {parseInt(hh) > 0 && `${hh}h`} {parseInt(mm) > 0 && `${mm}m`} {parseInt(ss) > 0 && `${ss}s`}
        </Text>
    </Wrapper>
};

export default Countdown;