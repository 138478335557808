import React, { createContext, useContext, useState } from "react"


interface MerchantInfo {
    logo: string;
    name: string;
}

interface GlobalContextInterface {
    setMerchantInfo: (info: MerchantInfo) => void;
    info: MerchantInfo | undefined;

    reset: () => void;
}

export const GlobalContext = createContext<GlobalContextInterface>({} as GlobalContextInterface);


export const GlobalContextProvider: React.FC = ({ children }) => {
    const [info, setMerchantInfo] = useState<MerchantInfo>();

    return <GlobalContext.Provider
      value={{
        reset: () => {
            setMerchantInfo(undefined);
        },
        setMerchantInfo,
        info
      }}
    >
      {children}
    </GlobalContext.Provider>
};

export function useLayoutInfo(): GlobalContextInterface {
    const context = useContext(GlobalContext);
    return context;
}
  