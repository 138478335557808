
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QRCodeExpiredVersionA from '../../components/layout/a/QrCodeExpiredVersionA';
import QrCodeVersionA from '../../components/layout/a/QrCodeVersionA';
import { useLayoutInfo } from '../../contexts/GlobalContext';
import { PayInResultData } from '../../types/payin';

const QRCodeV2: React.FC = () => {

    const location = useLocation();
    const { guid, hash } = useParams<{ hash: string, guid: string }>();
    const navigate = useNavigate();
    const state: PayInResultData = location.state as PayInResultData;
    const { setMerchantInfo } = useLayoutInfo();
    
    useEffect(() => {
        if (!state) {
            navigate(`/v2/${guid}/${hash}`);
        } else {
            setMerchantInfo({
                logo: state.accountBrandImage,
                name: state.accountName
            });
        }
    }, [ state ]);

    return <>
    {state.qrCodeIsExpired ? 
        <QRCodeExpiredVersionA {...state}/> :
        <QrCodeVersionA data={state} />    
    } 
    </>
};

export default QRCodeV2;
