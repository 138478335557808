import React from 'react';
import styled from 'styled-components';
import loadAsset from '../../utils/loadAsset';

const Img = styled.img`
    display: inline-block;
    vertical-align: middle;
    width: auto;
`;

const Wrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

export default function PixIcon() {
    return <Wrapper>
        <Img src={loadAsset('images/pix.svg')} alt='Pix' />
    </Wrapper>
}